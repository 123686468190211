/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.Wilson {
  height: unset;


  .wilson-header{
    border-bottom: red 6px solid;
    padding: 1rem 0;
  }

  .loadlogo {
    width: 200px;
    margin-bottom: 8px;
  }

  .djchat-top {
    margin-top: 2rem !important;
  }

  .jukebox-panel {
    // padding: 1.7rem 1.5rem 1.5rem 1.5rem 0;
    padding: 0;
    height: calc(100dvh - 226px);
    width: -webkit-fill-available;
  }

  .jukebox-divider {
    border-bottom: #aaa 1px solid !important;
  }

  .wilson-jukebox {
    display: flex;
    justify-content: center;
    width: 258px;
    height: 343px;
    background: url('https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2Fwilson-jukebox2.png?alt=media&token=4e31dfe5-f5ee-4441-a379-0963af23474a') no-repeat center center;
    margin: auto;

    img {
        margin-top: 20.2rem;
        width: 120px;
        height: 120px;
        border-radius: 9999px;
        box-sizing: border-box;
        display:block;
        // margin:auto;
    }

}

  .user-panel {
    padding: 1.7rem 1.5rem 1.5rem 1.5rem 0;
    height: calc(100dvh - 156px);
    width: -webkit-fill-available;
  }

  .panel-fade {
    --bg-opacity: 0.35 !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .chatlist-carousel-container {
    display: relative;
    z-index: 30;
  }

  .outer-container {
    max-width: 800px;
    margin: auto;
    display: flex;
    overflow: hidden;
    min-width: unset;
  }

  .outer-container > div {
    flex: 1;
  }

  .outer-container .carousel-wrapper {
    background: var(--orange);
    display: flex;
    flex-direction: column;
  }

  .outer-container .carousel-wrapper p,
  .carousel-item {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    line-height: 1.3;
  }

  .outer-container .content {
    background: var(--black);
  }

  .leading-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 6px;
  }

  .carousel {
    height: 100%;
    display: flex;
    flex: 5;
    align-items: center;
    color: var(--black);
  }

  .carousel-button {
    display: flex;
    align-self: center;
    padding: 0 10px;
    max-width: 50px;
    border-radius: 30px;
    color: #000;
    // background: var(--orange);
    // border: 2px solid var(--white);
    &.next {
      transform: rotate(90deg);
      color: #000;
    }
    &.prev {
      transform: rotate(-90deg);
      color: #000;
    }
  }

  .carousel-button svg {
    height: 26px;
    width: 26px;
  }
  .carousel-button path {
    fill: var(--black);
  }

  .slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }

  .carousel-inner {
    position: relative;
    max-height: 250px;
    height: 170px;
    margin-left: 5px;
    text-align: left;
    ul {
      margin-left: -1rem;
    }
    ul li {
      margin: 0 0 1rem;
    }
  }

  .carousel-inner::before,
  .carousel-inner::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 110px;
    pointer-events: none;
  }

  .carousel-inner::before {
    top: 0;
  }

  .carousel-inner::after {
    bottom: 0;
  }

  .carousel-item {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 112px;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  .carousel-item.unread,
  .chatlist-item.unread {
    position: absolute;
    left: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    // i{
    //   position: absolute;
    //   z-index: 100;
    //   font-size:  27px;
    //     color: black;
    // }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border-radius: 200px;
      background-color: yellow;
      animation: pulse-bolt 1.25s cubic-bezier(0.315, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 2px;
      height: 2px;
      background-color: yellow;
      border-radius: 200px;
      animation: circle-bolt 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
        infinite;
    }
  }

  .chatlist-item.unread {
    left: 14px;
    margin-bottom: 1.5rem;
    .red-badge {
      top: -5px;
      right: -5px;
    }
  }

  /* HTML: <div class="loader"></div> */
  .playani {
    width: fit-content;
    font-weight: bold;
    font-family: monospace;
    white-space: pre;
    font-size: 10px;
    color: yellow;
  }
  .playani:before {
    content: "PLAYING...";
    animation: l34 1s infinite alternate;
  }

  @keyframes l34 {
    0%,
    15%,
    75%,
    100% {
      content: "PLAYING...";
    }
    20% {
      content: "PLAYING...";
    }
    25% {
      content: "PL YING...";
    }
    30% {
      content: "PLAY NG...";
    }
    35% {
      content: " LAYING...";
    }
    40% {
      content: "PL YING...";
    }
    45% {
      content: "PLAYIN ...";
    }
    50% {
      content: "PLA ING...";
    }
    55% {
      content: "PLAYING  .";
    }
    60% {
      content: " LA ING...";
    }
    65% {
      content: "P AYING...";
    }
    70% {
      content: "PLAY N ...";
    }
  }

  .carousel-item.newchat,
  .object-cover.currentSong,
  .nub.activated {
    position: absolute;
    left: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    // i{
    //   position: absolute;
    //   z-index: 100;
    //   font-size:  27px;
    //     color: black;
    // }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border-radius: 200px;
      background-color: yellow;
      animation: pulse-bolt 1.25s cubic-bezier(0.315, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 2px;
      height: 2px;
      background-color: yellow;
      border-radius: 200px;
      animation: circle-bolt 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
        infinite;
    }
  }
  .nub.activated {
    left: -58px;
  }

  @keyframes pulse-bolt {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes circle-bolt {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.7);
    }
  }

  .carousel-item.visible {
    opacity: 1;
  }

  .muuzbox-wc-logo {
    width: 40px;
    height: 45px;
    // background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fmuuzbox-logo.png?alt=media&token=e0f887ad-e97a-4ebc-97cc-2d4fa0d79b1d)
    //   center center;
    background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FMuzzBox_Logo-03.svg?alt=media&token=fee86590-e4a7-4ca6-b4a9-13e85003fd31)
      center center;

    background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FMuzzBox_Logo-03.png?alt=media&token=ec552202-6f83-4a7c-ae76-82bafc9fb297)
      center center;

    background-size: cover;
    margin-top: 4px;
  }

  .muuxbox-wc-logo-pos {
    position: absolute;
    top: 62px;
    left: 0;
    transition: all 0.6s ease-in-out;
    &.activated {
      left: 2rem;
    }
  }

  .dj-wc-logo {
    width: 60px;
    height: 60px;
    background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fdj-obah.png?alt=media&token=44c52529-8bff-43a0-a646-b7a94b59a5ea)
      center center;
    background-size: cover;
    margin-top: 4px;
  }

  .muuz-confirm-panel {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    // max-height: 100%;
    // max-height: 950px;
    width: 80%;
    height: 72cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;
    // zoom: 150%;
    overflow: auto;
  }

  @media only screen and (max-height: 655px) {
    .muuz-confirm-panel {
      height: 75cqb;
    }
  }

  .muuz-busy-panel {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    // max-height: 100%;
    // max-height: 950px;
    width: 80%;
    height: 28cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;
    // zoom: 150%;
    overflow: auto;
  }

  @media only screen and (max-height: 655px) {
    .muuz-buzy-panel {
      height: 25cqb;
    }
  }

  .muuz-delete-confirm-panel {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    width: 80%;
    height: 20cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;

    overflow: auto;
  }

  @media only screen and (max-height: 655px) {
    .muuz-delete-confirm-panel {
      height: 45cqb;
    }
  }

  .muuz-edituser-panel {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    // max-height: 100%;
    // max-height: 950px;
    width: 80%;
    height: 59cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;
    // zoom: 150%;
    overflow: auto;
  }

  .muuz-displayname-panel {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    // max-height: 100%;
    // max-height: 950px;
    width: 80%;
    height: 20cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;
    // zoom: 150%;
    overflow: auto;
  }

  .muuz-confirm-delete-panel {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 100%;
    // max-height: 100%;
    // max-height: 950px;
    width: 80%;
    height: 25cqb;
    // opacity:0;
    z-index: 40;
    // transition: 0.5s;
    padding: 16px;
    // zoom: 150%;
  }

  .threedot-container {
    padding: 0px 12px;
  }

  .thredotmenu {
    width: 20px;
    height: 20px;

    background-image: radial-gradient(circle, black 2px, transparent 3px);
    @media (prefers-color-scheme: dark) {
      background-image: radial-gradient(circle, black 2px, transparent 3px);
    }
    background-size: 100% 33.33%;
  }

  .hamburgermenu {
    width: 20px;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
  }

  @media (prefers-color-scheme: dark) {
    .hamburgermenu {
      background-color: #000;
      height: 4px;
      margin: 3px 0;
      width: 24px;
    }
  }

  /* HTML: <div class="loader"></div> */
  .coverart-ani {
    // width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 0 0 0 #0004;
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  .coverart-ani:before,
  .coverart-ani:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #0004;
    animation: inherit;
    animation-delay: -0.5s;
  }
  .coverart-ani:after {
    animation-delay: -1s;
  }
  @keyframes l2 {
    100% {
      box-shadow: 0 0 0 40px #0000;
    }
  }

  .modal-container {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-weight: bold;
    color: #62626e;
  }

  .modal-content {
    width: 80%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #d8dce3;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #f7f8fa;
  }

  .modal-header {
    padding: 9px 10px;
    padding-left: 20px;
    height: 38px;
    font-size: 15px;
    position: relative;
  }

  .cross-btn {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    position: absolute;
    right: 10px;
    font-weight: bold;
    cursor: pointer;
  }

  .close-request-btn {
    height: 4rem;
    width: 5rem;
  }

  .modal-body {
    flex-grow: 1;
    border-top: solid 1px #d8dce3;
    padding: 14px 29px 14px 19px;
  }

  .modal-footer {
    border-top: solid 1px #d8dce3;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
  }

  .close-btn {
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px 0px;
    background-color: rgb(77, 144, 255);
    color: rgb(247, 248, 250);
    height: 35px;
    width: 117px;
    font-size: 14px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(43, 123, 255);
    cursor: pointer;
  }

  .rac-padding {
    padding-top: 1rem !important;
  }

  #close-request-trigger {
    padding: 0.5rem;
    width: auto;
    display: flex;
    align-items: center;
  }

  .sidebar {
    width: 0rem;
    z-index: 1;
    transition: 0.5s;
    padding: 1.7rem 1.5rem 1.5rem 2rem;
  }
  .sidebar.active {
    //left: 0;
    width: 7rem;
  }

  .mobile-chatlist {
    margin-top: 2.5rem !important;
    height: 500px;
  }

  .chat-list-control {
    left: -8rem;
    top: 3rem;
    transition: all 0.6s ease-in-out;
    &.activated {
      left: 0;
    }
  }

  .chatlist-btn {
    position: absolute;
    top: -5rem;
    // top: 11rem;
    transform: translate(0, 100%);
    left: -7rem;
    height: 17rem;
    width: 4rem;
    transition: all 0.6s ease-in-out;
    background-color: #2e6cb5;
    &.activated {
      left: -3px;
    }
    .nub {
      // background-color: blue;
      color: #fff;
      transform: rotate(90deg);
      width: 25rem;
      margin-top: 7rem;
    }
  }

  .vote-btn {
    position: absolute;
    top: -5rem;
    transform: translate(0, 100%);
    left: -7rem;
    height: 17rem;
    width: 4rem;
    transition: all 0.6s ease-in-out;
    background-color: #2e6cb5;
    &.activated {
      left: -3px;
    }
    .nub {
      // background-color: blue;
      color: #fff;
      transform: rotate(90deg);
      width: 15rem;
      margin-top: 7rem;
    }
  }

  .wilson-vote-btn {
    top: 13rem;
    right: -2px;
    width: 40px;
    height: 17rem;
    
    background-color: #2e6cb5;
    .nub {
        // background-color: blue;
        color: #fff;
        transform: rotate(90deg);
        width: 15rem;
        margin-top: 7rem;
      }
  }

  .mobile-w-full {
    margin-left: 32px;
  }

  .thredotmenu-container {
    margin-bottom: 1rem;
    // margin-left: 16px;
  }

  .text-enter-fit {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    .muuzmenuitem {
      background: #ffffff
        url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2Fleft-arrow.png?alt=media&token=030b66b4-0c2f-4501-8985-ad4b3e33a9df)
        left center no-repeat;
      background-size: contain;
      opacity: 0.65;
    }
    &.requestChat {
      bottom: 3rem;
    }
  }

  .right--10 {
    right: -10rem;
  }

  .accordion-list {
    .accordion-container {
      max-width: 650px;
      width: 100%;
      position: relative;
    }
    .wrapper {
      border-bottom: 1px solid #ffffff;
      overflow: hidden;
    }
    .wrapper.dark {
      border-bottom: 1px solid #000000;
      overflow: hidden;
    }
    .wrapper .genreType-container {
      width: 100%;
      text-align: left;
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    .genreType-container.active {
      color: #1db954;
      background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.04),
        transparent
      );
    }
    .wrapper .genreType-container:hover {
      background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.04),
        transparent
      );
    }
    .wrapper .arrow {
      font-size: 2rem;
      transition: 0.5s ease-in-out;
    }
    .arrow.active {
      rotate: 180deg;
      color: #1db954;
    }
    .wrapper .answer-container {
      // display:none;
      opacity: 0;
      height: 0;
      padding: 0 1rem;
      transition: height 0.7s ease-in-out;
      transition: opacity 2s ease-in-out;
      &.active {
        // display: block;
        opacity: 1;
        height: auto;
      }
    }

    .wrapper .answer-content {
      padding: 1rem 0;
      font-size: 20px;
      font-style: italic;
    }
  }

  .scrollable-section {
    height: calc(100dvh - 300px);
  }

  .autoplay-content .scrollable-section {
    height: calc(100dvh - 300px);
    width: 50%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .dropdownmenu {
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(12px);
    border: 1px solid #ffffff;
  }

  .dropdownmenu li {
    border-bottom: #eeeeee 1px solid;
  }

  .dropdownmenu li:last-child {
    border-bottom: none;
  }

  .chatuser img {
    width: 33px;
  }

  .hide-chat {
    width: 20px;
    // justify-content: flex-end;
    margin-left: auto;
  }

  .drop-down-panel-fade {
    color: #000 !important;
    // --bg-opacity: 0.5 !important;
    // background-color: (247,250,252,var(--bg-opacity));
  }

  .muuzbox-loader-logo-sm2 {
    position: absolute;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    transform: translate(25%, -200%);
    text-align: -webkit-center;
    // z-index: -1;
    img {
      width: 50px;
      height: 50px;
    }
    /* HTML: <div class="loader"></div> */
    .muuzbox-loader-blob {
      height: 20px;
      // aspect-ratio: 2;
      border: 5px solid transparent;
      box-sizing: border-box;
      background: radial-gradient(farthest-side, #000 98%, transparent)
          left/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) left/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) center/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) right/10px 10px,
        transparent;
      background-repeat: no-repeat;
      filter: blur(1px) contrast(10);
      animation: l14 1s infinite;
    }
  }

  .muuzbox-loader-logo-sm3 {
    position: absolute;
    top: 50%;
    left: 0;
    // transform: translate(-50%, -50%);
    transform: translate(10%, -100%);
    text-align: -webkit-center;
    // z-index: -1;
    img {
      width: 50px;
      height: 50px;
    }
    /* HTML: <div class="loader"></div> */
    .muuzbox-loader-blob {
      height: 20px;
      // aspect-ratio: 2;
      border: 5px solid transparent;
      box-sizing: border-box;
      background: radial-gradient(farthest-side, #000 98%, transparent)
          left/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) left/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) center/10px 10px,
        radial-gradient(farthest-side, #000 98%, transparent) right/10px 10px,
        transparent;
      background-repeat: no-repeat;
      filter: blur(1px) contrast(10);
      animation: l14 1s infinite;
    }
  }

  .coverimage {
    margin-top: 5px;
    width: 50px;
    height: 50px;
    transition: transform 0.2s;
  }

  .rotate {
    animation: rotation 3s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media only screen and (max-width: 832px) {
    .coverimage {
      display: none;
    }
  }

  * {
    box-sizing: border-box;
  }
  $duration: 30s;

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: rgba(#000, 0.9);
    padding-left: 100%;
    box-sizing: content-box;

    .ticker {
      display: inline-block;
      height: 4rem;
      line-height: 4rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;

      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: $duration;
      animation-duration: $duration;

      &__item {
        display: inline-block;

        padding: 0 2rem;
        font-size: 2rem;
        color: black;
      }
    }
  }

  .pagetemplatewrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    z-index: 11;
  }

  .currentplaylist {
    height: 290px;
  }
  // @media only screen and (min-height: 685px) {
  //   .currentplaylist {
  //     height: 300px;
  //   }
  // }

  .editplayer {
    // background-color: #e5e5f7;
    // opacity: 1;
    // background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 40px ), repeating-linear-gradient( #f5f5f855, #f5f5f8 );
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .dropdown {
    position: relative;
    button {
      // height: 2.5rem;
      // padding: 0 1.25rem;
      // background: #333;
      color: #000;
      // border-radius: 0.25rem;
      // font-size: 1rem;
      cursor: pointer;
    }
    nav {
      position: absolute;
      top: 100%;
      // left: 0;
      opacity: 0;
      background: #fff;
      visibility: hidden;
      transition: 300ms all;
      border: 2px solid #333;
      border-radius: 0.25rem;
      width: 12.5rem;
      ul {
        padding: 0.188rem 0;
        li {
          a {
            display: block;
            padding: 0.438rem 0.625rem;
            color: #333;
            &:hover {
              background: #f8f8f8;
            }
          }
        }
      }
    }
    &:focus-within nav {
      opacity: 1;
      visibility: visible;
      transform: translateY(0.25rem);
    }
  }

  .anim-one {
    position: relative;
    animation: hide 0.5s forwards ease-in-out;
    animation-delay: 0.2s;
  }

  @keyframes hide {
    0% {
      // transform: translate(0);
      left: 0;
    }
    20% {
      // transform: translate(5px);
      left: 5%;
    }
    50% {
      // transform: translate(-120vw);
      left: 120%;
    }
    80% {
      // transform: translate(5px);
      left: 5%;
    }
    100% {
      // transform: translate(0);
      left: 0;
    }
  }

  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    // position: absolute;
    width: 100px;
  }

  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
  }

  .marquee2 span {
    animation-delay: 2.5s;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  //////////////////////////////////// genre list efx //

  .tag-list {
    width: 28rem;
    &.activated {
      max-width: 24rem;
    }
    @media only screen and (min-width: 390px) {
      width: 31rem;
    //   max-width: 31rem;
      &.activated {
        max-width: 27rem;
      }
    }
    @media only screen and (min-width: 412px) {
      width: 33rem;
    //   max-width: 33rem;
      &.activated {
        width: 29rem;
        max-width: 29rem;
      }
    }
    @media only screen and (min-width: 430px) {
      width: 35rem;
    //   max-width: 35rem;
      &.activated {
        width: 30rem;
        max-width: 30rem;
      }
    }
    max-width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
  }

  .loop-slider {
    .inner {
      display: flex;
      width: fit-content;
      animation-name: loop;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: var(--direction);
      animation-duration: var(--duration);
      &:hover {
      }
    }
  }

  .tag {
    display: inline-block; //flex;
    align-items: center;
    gap: 0 0.2rem;
    color: #e2e8f0;
    font-size: 1.8rem;
    background-color: #334155;
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem; // Must used margin-right instead of gap for the loop to be smooth
    box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 20%),
      0 0.1rem 0.5rem rgb(0 0 0 / 30%), 0 0.2rem 1.5rem rgb(0 0 0 / 40%);

    span {
      font-size: 1.2rem;
      color: #64748b;
    }
  }

  .fade {
    pointer-events: none;
    background: linear-gradient(
      90deg,
      #1e293b,
      transparent 30%,
      transparent 70%,
      #1e293b
    );
    position: absolute;
    inset: 0;
  }

  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  //////////////////////////////////

  @media only screen and (max-width: 600px) {
    .pagetemplatewrapper {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 60px;
      margin: 0 auto;
      padding: 0;
      z-index: 11;
    }
  }

  @keyframes l14 {
    100% {
      background-position: right, left, center, right;
    }
  }

  @keyframes blockMove {
    0% {
      transform: translateY(102%);
    }
    35% {
      transform: translateY(0);
    }
    55% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-102%);
    }
  }

  @keyframes itemsOpacity {
    0% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .muuzbox-list {
    width: 650px;
  }
  @media only screen and (max-width: 767px) {
    .muuzbox-list {
      width: 100%;
      &.hide-list {
        display: none;
      }
    }
  }

  .muuzbox-shop {
    display: none;
    &.show-shop {
      display: block;
    }
  }

  @media only screen and (min-width: 767px) {
    .muuzbox-shop {
      display: block;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .notmobile {
      display: none;
    }
    .mobile {
      display: block;
    }

    .menu-open {
      position: relative;
      display: block;
      left: auto;
      opacity: 1;
      transition: 0.5s;
    }

    .menu-open.menu-collapse {
      position: relative;
      left: -100px;
    }

    .menu-open.menu-fade {
      opacity: 0;
    }

    .menu-open.menu-fade2 {
      display: none;
      transition-delay: 5s;
    }
  }

  .embla {
    max-width: 24rem;
    &.activated {
      max-width: 28rem;
    }
    @media only screen and (min-width: 390px) {
      max-width: 27rem;
      &.activated {
        max-width: 31rem;
      }
    }
    @media only screen and (min-width: 412px) {
      max-width: 29rem;
      &.activated {
        max-width: 33rem;
      }
    }
    @media only screen and (min-width: 430px) {
      max-width: 30rem;
      &.activated {
        max-width: 35rem;
      }
    }
    // margin: auto;
    --slide-height: 8rem;
    --slide-spacing: 1rem;
    --slide-size: 82.5%;
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
  .embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
  }
  .embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
  }
  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }
  .embla__play {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    color: var(--text-body);
    font-weight: 700;
    font-size: 1.4rem;
    padding: 0 2.4rem;
    min-width: 8.4rem;
  }
  .embla__progress {
    border-radius: 1.8rem;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    background-color: var(--background-site);
    position: relative;
    height: 0.6rem;
    justify-self: flex-end;
    align-self: center;
    width: 13rem;
    max-width: 90%;
    overflow: hidden;
  }
  .embla__progress__bar {
    background-color: var(--text-body);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
  }
  .embla__controls {
    grid-template-columns: auto 1fr auto;
  }
  .embla__progress {
    justify-self: center;
    transition: opacity 0.3s ease-in-out;
    width: 8rem;
  }
  .embla__progress--hidden {
    opacity: 0;
  }
  .embla__progress__bar {
    animation-name: autoplay-progress;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
  .embla__progress--hidden .embla__progress__bar {
    animation-play-state: paused;
  }
  @keyframes autoplay-progress {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .notmobile {
      display: none;
    }
    .mobile {
      display: block;
    }

    .mobile-w-full {
      margin-left: unset;
    }

    .thredotmenu-container {
      margin-left: unset;
    }
    .chatlist {
      display: block;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .right--10 {
      right: unset;
    }
    .chatlist {
      display: none;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .mobile {
      display: none;
    }
    .notmobile {
      display: block;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .mobile {
      display: none;
    }
    .notmobile {
      display: block;
    }
  }
}
