.title {
  color: var(--titleColor);
  font-size: 10px;
  margin: 10px 0 0 0;
  width:146px;
}

@media only screen and (max-width: 600px) {
  .title {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
}
