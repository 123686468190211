// Colors
$white: #ffffff;

$light-blue: #e4f8ff;
$react-blue: #80d8f7ff;
$sky-blue: #66c9edff;
$sky-blue-2: #64c5e8ff;
$carolina-blue: #049ed6ff;
$dark-blue: #00698f;
$pewter-blue: #8fa9b3ff;

$color-purple-light: #f6f6fd;
$color-purple-light-2: #e6e6fa;

$shadow-black: #22242626;

// Color Scheme
$color-primary: $react-blue;
$color-secondary: $light-blue; 
$color-tertiary: $carolina-blue; 
$color-quaternary: $dark-blue;

// Shadows & Borders
$box-shadow: 0 .0625rem .125rem 0 $color-tertiary;
$border-light: 1px solid $color-primary;
$border-dark: 1px solid $color-tertiary;
$border-grey: 1px solid $color-quaternary;