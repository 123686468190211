* {
  margin: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-size: 16px;
}

#root {
  height: 100vmax;
  background-color: $color-secondary;
  font-family: $font-primary;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background-color: $color-secondary;
  z-index: -1;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: center;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
}

.youtube-search-page {
  display: block;
  width: 1127px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  // border-bottom: $border-light;
}

// Search Bar
.search-bar {
  position: relative;
  display: flex;
  width: auto;
  background: $white;
  font-size: 1rem;
  padding: 1rem;
  border: $border-light;
  box-shadow: $box-shadow;
  border-radius: .25rem;
  margin-top: 0;
  margin-bottom: 1.25rem;
  @include respond-to ($breakpoint-small) {
    margin-bottom: 0.75rem;
    padding: 0.5rem;
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    flex: 230px 0;
    align-items: center;
    background-image: url('./icons/reactLogo-backgroundImage.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 26%;

    .logo {
      width: 60px;
      height: 60px;
    }
    @media (prefers-reduced-motion: no-preference) {
      .logo {
        animation: App-logo-spin infinite 20s linear;
      }
    }

    h1 {
      margin: 0 0 0 3px;
      font-size: 2.1875rem;
      font-family: $font-secondary;
      word-spacing: -9px;
    }
  }
  .form {
    position: relative;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 1rem;
    .field {
      
      label {
        display: block;
        margin: 0 0 .28571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        font-weight: 700;
        text-transform: none;
      }
      input {
        width: 100%;
        padding: .59375rem .875rem;
        border: $border-dark;
        box-sizing: border-box;
      }
    }
  }
}

.video-content-section {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100% !important;
  padding: 0 0 1rem 0;

  @include respond-to ($breakpoint-small) {
    flex-direction: column;
  }

  .video-player-container {
    position: relative;
    // background: $color-secondary;
    display: inline-block;
    flex: 30% 0;
    vertical-align: top;
    padding: 0 1rem 0 0;
    width: 30%;
    
    @include respond-to ($breakpoint-small) {
      width: 100%;
      padding: 0;
    }

    .video-player {
      position: relative;
      max-width: 100%;
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      // background: $color-secondary;

      iframe {
        position: absolute;
        border: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
      }
    }
    .video-description {
      position: relative;
      background: $white;
      padding: .875rem;
      margin: 1rem 0;
      // border: 1px solid rgba(34,36,38,.15);
      // border-radius: .28125rem;
      // box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);

      @include respond-to ($breakpoint-small) {
        margin: 0.5rem 0 1.5rem 0;
      }

      h2 {
        font-size: inherit;
        color: $color-quaternary;
        margin: 0 0 10px 0;
      }
      p {
        font-size: inherit;
      }
    }
  }
  .video-previews-container {
    flex: 70% 0;
    padding: 0 .875rem;

    .video-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      padding: 0 0 0.75rem 0;
      margin: 0 0 0.75rem 0;
      border-bottom: $border-grey;
      
      @include respond-to ($breakpoint-large) {
        flex-direction: column;
      }
      @include respond-to ($breakpoint-small) {
        flex-direction: row;
      }
      
      img {
        max-width: 70px;
        margin: 0 0.5rem 0 0;

        @include respond-to ($breakpoint-large) {
          padding-bottom: 10px;
        }
        @include respond-to ($breakpoint-small) {
          padding-bottom: 0;
        }
      }
      .content {
        display: block;

        h3 {
          font-weight: 500;
        }
      }
    }
  }
}

.contact {
  padding: 2rem 0 1rem 0;
  .contact-items-container {
    justify-content: center;
    margin: 0 0 30px 0;
    .contact-item {
      color: $white;
      display: block;
      height: 4rem;
      width: 4rem;
      line-height: 4.3rem;
      font-size: 1.5rem;
      background-color: $color-quaternary;
      transition: background-color 0.15s ease-in-out;
      border-radius: 50%;
      margin: 0 20px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

      @include respond-to ($breakpoint-small) {
        height: 3rem;
        width: 3rem;
        line-height: 48px;
      }
    }
  }
  .footer {
    text-align: center;
    color: $color-quaternary;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}