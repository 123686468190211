$color_1: #61dafb;
$color_2: white;
$background-color_1: var(--scroll-bar-color);
$background-color_2: transparent;

/* Works on Chrome, Edge, and Safari */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $background-color_1;
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
  }
}
.JukeboxRequests {
  text-align: center;
}
.JukeboxRequests-logo {
  pointer-events: none;
  position: relative;
  left: 0;
  top: 20px;
  width: 190px;
  height: 190px;
}
.JukeboxRequests-logo2 {
  position: absolute;
}
.JukeboxRequests-link {
  color: $color_1;
}
.muuz-confirm-panel {
  position: fixed !important;
}
.muuz-displayname-panel {
  position: fixed !important;
}
.enzbody {
  position: relative;
  color: $color_2;
  height: 100vh;
  background: linear-gradient(135deg, #fc466b 0%, #008000 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.request-container {
  position: relative;
  background-color: $background-color_2;
  padding: 0;
  z-index: 1;
}
.btn-container {
  margin: 10px;
}
.chat-container {
  height: 65%;
  overflow-x: auto;
  border: none;
}
.chatbout-input {
  position: fixed;
  bottom: 1rem;
  left: 0;
  padding: 0 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .JukeboxRequests-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#response-chat {
  min-width: none;
  padding: 2rem;
}
